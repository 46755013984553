import "./styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { UserDispatchContext } from "../../context/userProvider";
import { getOptimizedImages } from "../../../utilities/utili";
// import { getFirstCharacters } from "../../../utilities/utili";

const Product = ({ product_categories_json, isMobile }) => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [singleProductData, setSingleProductData] = useState();
  const setCart = useContext(UserDispatchContext);
  const [quantity, setQuantity] = useState(1);
  const [buttonState, setButtonState] = useState();
  const [buttonText, setButtonText] = useState();
  const [buttonOpacity, setButtonOpacity] = useState();
  // const [variants, setVariants] = useState([]);
  // const [allProducts, setAllProducts] = useState([]);
  // const [selectedVariant, setSelectedVariant] = useState(null);

  let cartProducts =
    JSON.parse(localStorage.getItem("cartProducts-live")) || {};

  useEffect(() => {
    if (product_categories_json && slug) {
      const currentProduct = product_categories_json[0].products.filter(
        (ct) => ct.slug === slug
      );
      if (currentProduct) {
        console.log(currentProduct[0].stripe_product_id_test);
        console.log(cartProducts);
        setSingleProductData(currentProduct);

        document.title = currentProduct[0].product; // Set the page title
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
          metaDescriptionTag.content = currentProduct[0].description; // Set the meta description
        }

        if (currentProduct[0].quantity_available === 0) {
          setButtonState("disabled");
          setButtonText("SOLD");
        } else if (Object.keys(cartProducts).includes(currentProduct[0].stripe_product_id_test)) {
          setButtonState("disabled");
          setButtonText("ADDED!");
          setButtonOpacity("opacity-50")
        } else if (Object.keys(cartProducts).includes(currentProduct[0].stripe_product_id_live)) {
          setButtonState("disabled");
          setButtonText("ADDED!");
          setButtonOpacity("opacity-50")
        } else {
          setButtonState("");
          setButtonText("ADD TO CART");
          setButtonOpacity("")
        }
      } else {
        redirectToAllProducts();
      }
    } else {
      redirectToAllProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_categories_json, slug]);

  // useEffect(() => {
  //   if (product_categories_json && slug && id) {
  //     const data = product_categories_json;
  //     let allProducts = [];
  //     data.forEach((category) => {
  //       category.products.forEach((product) => {
  //         allProducts.push(product);
  //       });
  //     });
  //     setAllProducts(allProducts);
  //     const currentProduct = allProducts.filter(
  //       (product) =>
  //         product.slug === slug && product.product_id === parseInt(id)
  //     );
  //     if (currentProduct && currentProduct.length) {
  //       setSingleProductData(currentProduct);
  //       if (currentProduct[0].quantity_available === 0) {
  //         setButtonState("disabled");
  //         setButtonText("SOLD");
  //       } else if (
  //         Object.keys(cartProducts).includes(
  //           currentProduct[0].stripe_product_id_test
  //         )
  //       ) {
  //         setButtonState("disabled");
  //         setButtonText("ADDED!");
  //         setButtonOpacity("opacity-50");
  //       } else if (
  //         Object.keys(cartProducts).includes(
  //           currentProduct[0].stripe_product_id_live
  //         )
  //       ) {
  //         setButtonState("disabled");
  //         setButtonText("ADDED!");
  //         setButtonOpacity("opacity-50");
  //       } else {
  //         setButtonState("");
  //         setButtonText("ADD TO CART");
  //         setButtonOpacity("");
  //       }
  //       if (currentProduct[0].parent_variant) {
  //         const similarProducts = allProducts.filter(
  //           (product) =>
  //             product.parent_variant === currentProduct[0].parent_variant &&
  //             product.product_id !== currentProduct[0].product_id
  //         );
  //         let variants = [];
  //         if (
  //           currentProduct[0].option1 ||
  //           currentProduct[0].option2 ||
  //           currentProduct[0].option3
  //         ) {
  //           if (currentProduct[0].option1) {
  //             variants.push({
  //               option: currentProduct[0].option1,
  //               productId: currentProduct[0].product_id,
  //             });
  //             setSelectedVariant(currentProduct[0].option1);
  //           } else if (currentProduct[0].option2) {
  //             variants.push({
  //               option: currentProduct[0].option2,
  //               productId: currentProduct[0].product_id,
  //             });
  //             setSelectedVariant(currentProduct[0].option2);
  //           } else if (currentProduct[0].option3) {
  //             variants.push({
  //               option: currentProduct[0].option3,
  //               productId: currentProduct[0].product_id,
  //             });
  //             setSelectedVariant(currentProduct[0].option3);
  //           }
  //         }
  //         similarProducts.forEach((product) => {
  //           if (product.option1) {
  //             variants.push({
  //               option: product.option1,
  //               productId: product.product_id,
  //             });
  //           } else if (product.option2) {
  //             variants.push({
  //               option: product.option2,
  //               productId: product.product_id,
  //             });
  //           } else if (product.option3) {
  //             variants.push({
  //               option: product.option3,
  //               productId: product.product_id,
  //             });
  //           }
  //         });
  //         setVariants(variants);
  //       }
  //     } else {
  //       redirectToAllProducts();
  //     }
  //   } else {
  //     redirectToAllProducts();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [product_categories_json, slug]);

  const redirectToAllProducts = () => {
    navigate("/artwork");
  };

  const perspect = window.perspect;
  const getStripeProductId = (product) => {
    let stripeProductId;
    if (perspect.site_env === "test") {
      stripeProductId = product.stripe_product_id_test;
    } else {
      stripeProductId = product.stripe_product_id_live;
    }
    return stripeProductId;
  };

  const regex = /(<([^>]+)>)/gi;

  const addProductToCart = (
    productStripeId,
    productName,
    productPrice,
    productImage,
    count
  ) => {
    let cartProducts =
      JSON.parse(localStorage.getItem("cartProducts-live")) || {};

    if (Object.keys(cartProducts).includes(productStripeId)) {
      const currentCount = cartProducts[productStripeId].count;
      const currentProduct = cartProducts[productStripeId];
      cartProducts = {
        ...cartProducts,
        [productStripeId]: {
          ...currentProduct,
          count: currentCount + count,
        },
      };
    } else {
      cartProducts = {
        ...cartProducts,
        [productStripeId]: {
          count: count,
          product: productName,
          productPrice,
          productImage,
        },
      };
    }
    localStorage.setItem("cartProducts-live", JSON.stringify(cartProducts));
    setCart(cartProducts);
  };

  // const handleVarient = (varient, productId) => {
  //   selectedVariant(varient);
  //   if (productId !== singleProductData.product_id) {
  //     const filteredProduct = allProducts.filter(
  //       (product) => product.product_id === productId
  //     );
  //     setSingleProductData(filteredProduct);
  //   }
  // };

  return (
    <div className="pt-20 pb-6 my-10 product-page">
      <div className="container mx-auto mt-18">
        <div className="grid lg:grid-cols-2 mg:grid-cols-2 sm:grid-cols-1 box-gap">
          <section>
            <div className="container mx-auto product-cards lg:px-10">
              <div className="product-box">
                <div className="relative">
                  <div
                    id="product"
                    className="carousel slide carousel-fade relative"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner relative w-full overflow-hidden">
                      {singleProductData &&
                        singleProductData.map((items) =>
                          getOptimizedImages(isMobile, items.media).map(
                            (productImg, idx) => (
                              <div
                                key={idx}
                                className={`${idx === 0
                                    ? "carousel-item active relative float-left w-full"
                                    : "carousel-item"
                                  } `}
                              >
                                <img
                                  src={`https://${productImg.link}`}
                                  className="img-fluid w-100"
                                  alt=""
                                />
                              </div>
                            )
                          )
                        )}
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#product"
                        data-bs-slide="prev"
                      >
                        <span
                          // className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#product"
                        data-bs-slide="next"
                      >
                        <span
                          // className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>

                    {/* <div className="carousel-indicators right-0 bottom-0 left-0 flex justify-center p-0 mb-4 mt-8">
                      {singleProductData &&
                        singleProductData.map((items) =>
                          items.media.map((productImg, idx) =>
                            idx <= 8 ? (
                              <button
                                key={idx}
                                type="button"
                                data-bs-target="#product"
                                data-bs-slide-to={idx}
                                className={`${idx === 0 ? "active" : ""}`}
                                aria-current="true"
                                aria-label={`Slide ${idx + 1}`}
                              >
                                <img
                                  src={`https://${productImg.link}`}
                                  className="img-fluid w-20"
                                  alt=""
                                />
                              </button>
                            ) : (
                              ""
                            )
                          )
                        )}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {singleProductData &&
            singleProductData.map((items, idx) => (
              <div key={idx} className="pl-5">
                <h2 className="mb-2 text-[2rem]">{items.product}</h2>
                <div className="border-b py-2">
                  <span className="pricing font-light">${items.price}</span>
                  {/* {items?.parent_variant && (
                    <div className="mt-2 md:flex items-center">
                      <label className="mr-4">
                        <span>Size</span>
                        <em>*</em>
                      </label>

                      <div className="flex items-center justify-start md:w-1/2">
                        {variants &&
                          variants.length &&
                          variants.map((variant, index) => {
                            return (
                              <div
                                key={index}
                                className={`variant ${
                                  index + 1 < variants.length
                                    ? "mr-[10px] "
                                    : ""
                                } ${
                                  selectedVariant === variant.option
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => {
                                  handleVarient(
                                    variant.option,
                                    variant.productId
                                  );
                                }}
                              >
                                <div class="cursor-pointer rounded-full border-2 font-bold border-[#323232] h-[32px] w-[32px] flex items-center justify-center variant-option">
                                  {getFirstCharacters(variant.option)}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )} */}
                </div>
                <div className="flex-container mt-3">
                  {/* <p className="mr-2 font-semibold">QUANTITY</p>
                  <input
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    type="number"
                    min="1"
                    className="w-16 input-style"
                  /> */}
                </div>
                <div className="mt-8 mb-5">
                  <button
                    type="button"
                    disabled={buttonState}
                    className="site-btn mr-3 w-60 {opacity}"
                    onClick={() =>
                      addProductToCart(
                        getStripeProductId(items),
                        items.product,
                        items.price,
                        getOptimizedImages(isMobile, items.media)?.link,
                        parseInt(quantity)
                      )
                    }
                  >
                    {buttonText}
                  </button>
                </div>
                <div className="border-t py-4">
                  {/* <h6 className="mb-3">VIEW STORE INFORMATION</h6> */}
                  <p className="mb-3">
                    <div
                      dangerouslySetInnerHTML={{ __html: items.description }}
                    />
                  </p>
                </div>
                <div className="border-t py-4">
                  <p className="mb-3 italic">
                    Ready-to-display, beautifully printed on 20x20 no-grain
                    plywood, comfortably shipped.
                  </p>
                </div>
                <div className="border-t py-4">
                  <p className="mb-3 italic">
                    All artwork on Museum of Jewish Art is one-of-a-kind and
                    therefore cannot be purchased more than once and will be
                    removed from being available shortly after purchase.
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Product;
