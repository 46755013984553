export const setItem = (itemname, item) => {
  localStorage.setItem(itemname, item);
};

export const getItem = (itemname) => {
  return localStorage.getItem(itemname);
};

export const removeItem = (itemname) => {
  localStorage.removeItem(itemname);
};

export const getFormattedDate = (date) => {
  const formattedDate = new Date(date);
  return formattedDate.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const getImageFromList = (media, size) => {
  console.log("User requesting a " + size + " image");
  let image = "";
  console.log(media);
  // if (size == 'thumbnail'){

  // }
  // else if (size == 'very_small'){

  // }
  // else if (size == 'small'){

  // }
  // else if (size == 'medium_small'){

  // }
  // else if (size == 'medium'){

  // }
  // else if (size == 'medium_large'){

  // }
  // else if (size == 'large'){

  // }
  // else if (size == 'very_large'){

  // }
  // else if (size == 'original'){

  // }

  for (let index in media) {
    let item = media[index];
    if (item.size === size) {
      image = item.link;
      console.log("Returning image: " + image);
    }
  }

  return image;
};

// export function getFirstCharacters(inputString) {
//   // Split the input string into an array of words
//   const words = inputString.split(" ");

//   // Map over each word and return its first character
//   const firstChars = words.map((word) => word.charAt(0));

//   // Join the resulting array of first characters into a string
//   const result = firstChars.join("");

//   return result;
// }

export const getOptimizedImages = (isMobile, media) => {
  const getLargeImages = () => {
    return media.filter(
      (image) =>
        image.size !== "small" &&
        image.size !== "very_small" &&
        image.size !== "medium_small" &&
        image.size !== "thumbnail"
    );
  };
  const getAllSmallImages = () => {
    return media.filter(
      (image) =>
        image.size === "small" ||
        image.size === "very_small" ||
        image.size === "medium_small" ||
        image.size === "thumbnail"
    );
  };
  const getSmallImage = () => {
    return media.filter((image) => image.size === "small");
  };
  if (media.length) {
    if (isMobile) {
      if (getSmallImage().length) {
        return getSmallImage();
      } else {
        return getLargeImages();
      }
    } else {
      if (getLargeImages().length) {
        return getLargeImages();
      } else {
        return getAllSmallImages();
      }
    }
  } else {
    return [];
  }
};