import "./styles.scss";

const About = () => {
  return (
    <div className="page-content about">
      <div className="relative">
        <div
          className="flex-container justify-center bg-cover bg-no-repeat bg-fixed banner-bg"
          style={{
            backgroundImage: "url(//museumofjewishart.com/80731854616588066003269089636936106/tmpmy96j2se-750x180.jpg)",
          }}
        >
          <div className="text-center z-10">
            <h1 className="text-white">
              About <span className="primary-clr">Us</span>
            </h1>
            <h2 className="text-white">{window.perspect.site_description}</h2>
          </div>
        </div>
      </div>
      <section>
        <div className="container mx-auto py-8">
          <div className="text-center py-3">
            <h3 className="text-3xl font-bold mb-8 relative main-h">
              Artwork
            </h3>
          </div>
          <div className="grid lg:grid-cols-2 mg:grid-cols-2 sm:grid-cols-1 box-gap">
            <div>
              <img
                src="//museumofjewishart.com/232420620185520322381038571262039434/9-beautiful-6-marked-750x750.png"
                className="img-fluid w-100"
                alt=""
              />
            </div>
            <div>
            <p className="mb-3">
              I am not Jewish, but I want to be more aware of my community and plan to learn through art.  I am absolutely appalled by the anti-Semitism in the United States and abroad.  It's encumbent upon all decent people to oppose such bigotry.
            </p>
            <p className="mb-3">
              The history of Jewish art is a rich and varied one, spanning thousands of years and covering a wide range of cultures and locations. Jewish art has its origins in ancient Israel, where artifacts such as the Dead Sea Scrolls and the Lachish reliefs provide a glimpse into the religious and cultural practices of the time. In the centuries that followed, Jewish art flourished in the courts of medieval Spain and in the synagogues of Eastern Europe, where illuminated manuscripts and ornate metalwork were created. Jewish art also played a vital role in the cultural life of the Jewish communities in the Islamic world, where artists produced a wide range of works, including illuminated manuscripts and decorative objects. With the rise of the Jewish Enlightenment in the 18th and 19th centuries, Jewish art began to take on new forms, as Jewish artists began to engage with the styles and themes of the broader European art world. This trend continued in the 20th century, as Jewish artists began to experiment with new styles and mediums, such as abstract expressionism and conceptual art. Today, Jewish art continues to evolve and change, as Jewish artists around the world continue to explore new forms and themes in their work.
            </p>
            <p className="mb-3">
              Every piece of artwork sold by the Museum of Jewish Art is unique.  Sold artwork will be removed from sale shortly after being purchased.
            </p>
            <p className="mb-3">  
              There will only be one printed copy of a single piece of artwork.
            </p>
            <p className="mb-3">  
              Delivery can take between 1-2 weeks.
            </p>
              {/* <p className="mb-3">
                You can write about your site here.
              </p> */}
            </div>
          </div>
        </div>
      </section>

      {/* About view Modal */}
      <section className="bg-primary-clr py-10">
        <div className="container mx-auto text-center text-white pb-6">
          <div
            id="about-slider"
            className="carousel slide relative"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner relative w-full overflow-hidden md:h-80 mb-6">
              <div className="carousel-item active relative float-left w-full">
                <div className="mb-5 md:mx-20">
                  <img
                    src="//museumofjewishart.com/291234596667476679406320117034115623/5-close-up-portrait-3-marked-100x100.png"
                    className="img-fluid w-20 h-20 mx-auto rounded-full mb-4"
                    alt=""
                  />
                  <span className="font-semibold text-lg">Mission</span>
                  <p>
                  The Museum of Jewish Art's mission is to help make your living space thought-provoking and beautiful. Our goal is to showcase a diverse range of works, representing different styles, periods, and cultures. We strive to educate and inspire our audience through the beauty and meaning of Jewish art. We hope to foster a deeper appreciation and understanding of Jewish art and culture, and to contribute to the ongoing legacy of creativity and expression.
                  </p>
                </div>
              </div>
              <div className="carousel-item">
                <div className="mb-5 md:mx-20">
                  <img
                    src="//museumofjewishart.com/209527519580157643245855649853442672/10-Tallit-2-marked-100x100.png"
                    className="img-fluid w-20 h-20 mx-auto rounded-full mb-4"
                    alt=""
                  />
                  <span className="font-semibold text-lg">
                    Jewish Communities in the Islamic World
                  </span>
                  <p className="mb-3">
                    Some of the art that you see here will reflect the Jewish communities that have existed in the Islamic world for centuries, with a significant presence in countries such as Iraq, Iran, and Yemen. Jewish art in the Islamic world has been influenced by the dominant Islamic culture, as well as by the unique traditions and customs of the Jewish communities themselves. Many Jewish artists in the Islamic world have produced illuminated manuscripts, such as Torah scrolls, as well as metalwork, ceramics, and other decorative arts. In the early 20th century, Jewish artists in the Middle East and North Africa began to adopt modern artistic styles and techniques, resulting in a rich and diverse body of work that reflects the cultural and historical experiences of Jewish communities in the Islamic world.
                  </p>
                </div>
              </div>
              <div className="carousel-item">
                <div className="mb-5 md:mx-20">
                  <img
                    src="//museumofjewishart.com/97916088503460963460457883077966754/8-temple-mount-9-marked-100x100.png"
                    className="img-fluid w-20 h-20 mx-auto rounded-full mb-4"
                    alt=""
                  />
                  <span className="font-semibold text-lg">
                    Jewish Art: stories from the Talmud, Torah and the Bible
                  </span>
                  <p>
                    Jewish art has been used to tell stories from the Torah and Talmud for centuries. In the Middle Ages, illuminated manuscripts were created to illustrate stories from the Bible and to make them more accessible to the Jewish community. These manuscripts were richly decorated with intricate illustrations and ornate calligraphy, and were used in synagogues and homes for religious study and worship. In Eastern Europe, synagogues were adorned with colorful frescoes and intricate carvings that depicted scenes from the Bible, as well as symbols and imagery that were meaningful to the Jewish community. Today, Jewish artists continue to use the stories and themes from the Torah and Talmud as inspiration for their work, creating contemporary interpretations of these ancient texts through painting, sculpture, and other mediums.
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-indicators flex justify-center p-0 mb-4">
              <button
                type="button"
                data-bs-target="#about-slider"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#about-slider"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#about-slider"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
