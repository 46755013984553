import React, { useEffect, useState } from "react";

function SubscribeModal() {
  const showPopup = true;
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const isCookieExists = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`hidePopup=`))
    ?.split("=")[1];

  useEffect(() => {
    if (!isCookieExists) {
      setTimeout(() => {
        setShowModal(true);
      }, 200);
      if (!document.getElementById("subscribe-backdrop") && showPopup) {
        const backdrop = document.createElement("div");
        backdrop.id = "subscribe-backdrop";
        backdrop.classList.add("subscribe-backdrop");
        document.body.appendChild(backdrop);
        document.body.style.overflow = "hidden";
      }
    }
  }, [isCookieExists]);

  const handleCloseModal = (isFormSubmitted) => {
    const backdropElement =
      document.getElementsByClassName("subscribe-backdrop");
    for (let i = 0; i < backdropElement.length; i++) {
      backdropElement[i].remove();
    }
    document.body.style.overflow = "auto";
    setShowModal(false);

    if (!isFormSubmitted) {
      const hours = 1;
      //setting cookie with expiry time of 1 hour
      let now = new Date();
      now.setTime(now.getTime() + hours * 3600 * 1000);
      document.cookie =
        "hidePopup=true; expires=" + now.toUTCString() + "; path=/";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const headers = new Headers({
      "content-type": "application/json",
    });
    fetch("/csrf", {
      method: "GET",
      headers: headers,
    })
      .then(function (response) {
        return response.json();
      })
      .then((token) => {
        const form = new URLSearchParams();
        form.append("name", "");
        form.append("phone", "");
        form.append("email_address", email);
        form.append("comment", "");
        form.append("hp", "");
        form.append("csrf_token", token.csrf_token);

        fetch("/contact", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: form.toString(),
        })
          .then(function () {
            handleCloseModal();
          })
          .catch(function (error) {
            handleCloseModal();
            console.error("Error:", error);
          });
      }).catch((error) => {
        handleCloseModal();
        console.error("Error:", error);
      })
  };
  return (
    <div
      className={`modal ${showModal && showPopup ? "show" : ""} fade`}
      id="subscribeModal"
      aria-labelledby="subscribeModalTitle"
      aria-modal="true"
      role="dialog"
      style={{ display: "block", paddingLeft: "0px" }}
    >
      <div
        className="modal-dialog md:!w-[688px] !max-w-unset"
        style={{ maxWidth: "688px" }}
      >
        <div className="modal-content !rounded-none">
          <div className="modal-body relative flex p-0">
            <button
              type="button"
              className="btn-close absolute right-0 z-[999] !w-[43px] !h-[40px] bg-[length:23px]"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => handleCloseModal()}
            ></button>
            <div className="relative p-[20px] md:pr-[355px] md:pl-[30px] md:py-[30px] h-[521px] modal-box">
              <div className="md:block hidden">
                <img
                  src="https://depthngreen.net/52661681036556864199486669833246633/IMG_0617-1500x943.JPG"
                  className="side-img"
                  alt=""
                />
              </div>
              <div>
                <h6 className="mb-5 text-[48px] leading-[1.25] relative !pb-0 main-h">
                  STAY TUNED
                </h6>
                <p className="text-[17px] leading-[1.4]">
                  Subscribe to our newsletter for latest news. Our newsletter is
                  sent once a week, every Monday.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="form-group mt-10">
                    <label className="text-[17px] mb-1">Enter your email</label>
                    <input
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      className="input-style mb-6"
                    />
                  </div>
                  <button
                    type="submit"
                    className="!mb-0 site-btn !w-full text-[17px]"
                  >
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribeModal;
